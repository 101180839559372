// {"ini":true,"typ":"sl","lst":[
// "ot":"s",
// "id":48,
// "n":"Barre du Cengle",
// "fn":null,
// "a":43.503701,
// "g":5.614356,
// "t":420,
// "ts":1429963541,
// "p":null,
// "r":false,
// "f":null,
// "k":12,
// "nf":null,
// "oo":128,
// "ao":320,
// "fl":null,
// "_d":[],
// "_e":[],
// "_p":[],
// "_v":[],
// "_w":[],
// "_i":[]

import { timePast, dir2oriExtend, mylink, oridecorate, dow, dow3, bgInfo, callRaspData, objectViewed } from './libra'
import { app, glo } from './globo'

import BaseObject from 'ol/Object'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import Style from 'ol/style/Style'
import Icon from 'ol/style/Icon'

import { getTKindImgs } from './tof'

const ObjType = 's'
const mskDeta = 0b0000000000001
const mskDetU = 0b0000000000010
const mskEven = 0b0000000000100
const mskEveU = 0b0000000001000
const mskPmsg = 0b0000000010000
const mskPmsU = 0b0000000100000
const mskVots = 0b0000001000000
const mskVotU = 0b0000010000000
const mskWshs = 0b0000100000000
const mskWshU = 0b0001000000000
const mskAvai = 0b0010000000000
const mskOffi = 0b0100000000000

// local
const sitImgCache = {}
let sitPts = []
let localFactor = -1

function sitOneLiner (oid) {
  let sit
  if (typeof oid === 'number') {
    sit = app.getObj(ObjType, oid)
    if (!sit) { getSit(oid); return '' }
  } else { sit = oid }
  return 'Site ' + sit.n + (sit.fn ? (' ' + sit.fn) : '') + ' (#' + sit.id + ')'
}

function fillDays (resp) {
  const na = resp.sname
  const sid = resp.sid
  const lat = resp.lat
  const lgt = resp.lgt
  const runs = resp.runs
  let index = 0
  const e = document.getElementById('raspdays')

  e.innerHTML = '<div id="raspdays"></div>'
  Object.keys(runs).forEach((rok) => {
    index++
    const ro = runs[rok]
    const sd = ro.d
    const today = new Date()
    const day = new Date(sd.substring(0, 4) + '/' + sd.substring(4, 6) + '/' + sd.substring(6, 8) + ' 12:00:00')
    let jour
    if (day.toDateString() === today.toDateString()) {
      jour = '<label style="color:#00F;background-color:#FFF;font-weight:bold;">' + dow3(day.getDay()) + ':' + day.getDate() + '</label>'
    } else {
      if (index < 6) {
        jour = dow3(day.getDay()) + ':' + day.getDate()
      } else {
        jour = day.getDate()
      }
    }

    const elid = 'rd' + sid + '_' + index
    // $('#raspdays').append(' <span style="color:#000;background-color:#FFF;" title="' + ro.r + '" id="' + elid + '">' + jour + '</span>')
    const spe = document.createElement('span')
    spe.innerHTML = ' <span style="color:#000;background-color:#FFF;" title="' + ro.r + '" id="' + elid + '">' + jour + '</span>'
    e.append(spe)
    const elem = document.getElementById(elid)
    if (elem) { elem.onclick = function () { callRaspData(na, sid, ro.r, ro.d, lat, lgt) } }
    //        elem.addEventListener('click', function() {
    //            callRaspData(na, sid, ro.r, ro.d, lat, lgt);
    //        });
  })

// let rdel = document.getElementById('raspdays');
//    rdel.innerHTML = ih;
//
//    index=0;
//    $.each(runs, function(i, ro) {
//        index++;
//    let elid = 'rd'+sid+'_'+index;
//    let elem = document.getElementById(elid);
//        if( elem ) { elem.onclick = function() { callRaspData(na, sid, ro.r, ro.d, lat, lgt); }; }
//    });
}

function _sitDisplay (oid) {
  let sit
  if (typeof oid === 'number') {
    sit = app.getObj(ObjType, oid)
    if (!sit) { getSit(oid); return '' }
  } else { sit = oid }
  app.selectObj(ObjType, sit.id)
  let txt = '<fieldset><legend><span id="b_back"></span> &nbsp; <span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Site '
  if (!sit.av) { txt += '<span style="background:red;color:white">sans d&eacute;co</span> ' }
  txt += '<span style="font-weight:bold">' + sit.n + ((sit.fn && sit.fn.length > 0) ? '/' + sit.fn : '') + '</span> (#' + sit.id + ')</span>'
  txt += '</legend><table>'
  if (typeof sit.r !== 'undefined') { txt += '<tr><td><b>RASP</b></td><td><div id="raspdays"></div></td></tr>' }
  //    if( typeof sit.mc !== 'undefined' && sit.mc !== null ) { txt += '<tr><td>mc:'+sit.mc+'</td><td><div id="mcdays"></div></td></tr>'; }
  if (sit.fl && sit.fl > 0) {
    let order = 0
    txt += '<tr><td colspan="2">'
    sit._i.sort(function (a, b) { return a < b })
    for (const n of sit._i) {
      order++
      const inf = app.getObj('i', n)
      const guy = app.getObj('g', inf.gi)
      const tof = app.getObj('t', inf.ti)
      let na = '...'; let titna = na
      if (!guy) { app.getLater('g', inf.gi) } else {
        na = guy.sn
        titna = guy.fn + ' ' + guy.ln
        if (!na || na === '') { na = titna }
      }
      const now = new Date()
      const sentd = new Date(inf.ts * 1000)
      const difmil = now.getTime() - sentd.getTime()
      const sentod = dow(sentd.getDay()) + ' ' + sentd.getDate() + '/' + (sentd.getMonth() + 1) + ' ' + sentd.getHours() + ':' + sentd.getMinutes()
      txt += '<fieldset style="color:black;background-color:#' + bgInfo(inf.fl) + ';"><legend><span id="oc_s_i' + order + '_left" style="font-size:large">'
      txt += (order > 1) ? glo.symb.deplig.c : glo.symb.plig.c
      txt += '</span>&nbsp;&nbsp;<u><span id="go_t_' + tof.id + '" style="font-weight:bold">' + tof.n + ' ' + ((inf.fl < 1 || inf.fl > 3) ? 'NON' : '') + ' Volable</span></u>'
      txt += '</legend>'
      txt += '<div id="d_s_i' + order + '" style="display:' + ((order > 1) ? 'none' : 'block') + ';visibility:' + ((order > 1) ? 'hidden' : 'visible') + '">'
      txt += '<table><tr><td>Pilote</td><td><span id="go_g_' + guy.id + '" title="' + titna + '"><u>' + na + '</u></span></td></tr>'
      txt += '<tr><td>Orient.(Dir) '
      if (inf.lo && inf.lo >= 0) { txt += 'Min/' }
      txt += 'Moy/Max</td><td><span style="font-weight:bold">' + dir2oriExtend(inf.di) + '</span> (' + inf.di + '&deg;) '
      //            if( inf.lo!=undefined && inf.lo!=null && inf.lo!=-1 ) {
      if (inf.lo && inf.lo >= 0) { txt += inf.lo + ' /' }
      txt += ' <span style="font-weight:bold">' + inf.sp + '</span> / '
      if (inf.gu && inf.gu >= 0) {
        txt += inf.gu
      } else { txt += '-' }
      txt += ' km/h</td></tr>'
      if (typeof inf.ne !== 'undefined' && inf.ne !== null && inf.ne >= 0 && inf.ne <= 8) {
        txt += '<tr><td>N&eacute;bulosit&eacute;</td><td>' + inf.ne + ' Octa</td></tr>'
      }
      if (typeof inf.te !== 'undefined' && inf.te !== null) {
        txt += '<tr><td>Temp&eacute;rature</td><td>' + inf.te + ' &deg;C</td></tr>'
      }
      if (typeof inf.pr !== 'undefined' && inf.pr !== null && inf.pr > 900) {
        txt += '<tr><td>Pression</td><td>' + inf.pr + ' hPa</td></tr>'
      }
      if (typeof inf.v !== 'undefined' && inf.v > 0) {
        txt += '<tr><td>Validit&eacute;</td><td>' + inf.v + ' h</td></tr>'
      }
      txt += '<tr><td>Quand</td><td>' + sentod + ' (<span style="font-weight:bold">' + timePast(difmil) + '</span>)</td></tr>'

      if (inf.m !== null && inf.m !== 'null' && inf.m.length > 0) { txt += '<tr><td colspan="2">' + inf.m + '</td></tr>' }
      txt += '</table></div></fieldset>'
    }
    txt += '</td></tr>'
  }
  if (sit._p && sit._p.length > 0) { // was FFFF7A
    txt += '<tr><td colspan="2"><fieldset style="color:black;background-color:#FFF;"><legend align="right">'
    txt += '<span style="background-color:#FFFF7A;font-weight:bold;font-size:130%;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ' + sit._p.length + ' Post\'It' + ((sit._p.length > 1) ? 's' : '') + ' &nbsp; &nbsp; </span>'
    txt += '<span id="oc_s_p" style="background-color:#FFFF7A;font-weight:bold;font-size:large"> ' + glo.symb.plid.c + ' </span>'
    txt += '</legend><div id="d_s_p" style="display:block;visibility:visible"><table>'
    for (const n of sit._p) {
      const pmg = app.getObj('p', n)
      if (pmg && app.pmgMatchTime([n])) {
        const guy = app.getObj('g', pmg.gi)
        const pad = new Date(pmg.sa * 1000)
        const pod = new Date(pmg.so * 1000)
        const opad = dow(pad.getDay()) + ' ' + pad.getDate() + '/' + (pad.getMonth() + 1) + ' ' + pad.getHours() + ':' + pad.getMinutes()
        let na = '...'; let titna = na
        if (!guy) { app.getLater('g', pmg.gi) } else {
          na = guy.sn
          titna = guy.fn + ' ' + guy.ln
          if (!na || na === '') { na = titna }
        }
        const bc = pmg.seen() ? '' : ' style="background-color:#FFFF7A"'
        txt += '<tr id="tr_p_' + pmg.id + '"' + bc + '><td>'
        if (glo.me.id && (glo.me.id === pmg.gi || glo.me.id === 1)) {
          txt += '<span id="ed_p_' + pmg.id + '" style="font-size:large" title="modifier"> ' + glo.symb.mod.c + ' </span>'
        }
        txt += '<span id="us_p_' + pmg.id + '" style="font-size:large" title="non-vu"> ' + glo.symb.nonvu.c + ' </span>'
        txt += '(<span id="go_g_' + guy.id + '" title="' + titna + '"><u>' + na + '</u></span>) '
        if (((pod - pad) < 86400000) && (pad.getDate() === pod.getDate())) {
          const ha = pad.getHours()
          const ma = pad.getMinutes()
          const ho = pod.getHours()
          const mo = pod.getMinutes()
          txt += 'Le ' + dow(pad.getDay()) + ' ' + pad.getDate() + '/' + (pad.getMonth() + 1) + '</td><td>'
          if (ha === 0 && ho === 23 && ma === 0 && mo === 59) { // Journée
            txt += 'journ&eacute;e'
          } else {
            txt += 'de ' + ha + ':' + ma + ' &agrave; ' + ho + ':' + mo
          }
          txt += '</td></tr>'
        } else {
          const opod = dow(pod.getDay()) + ' ' + pod.getDate() + '/' + (pod.getMonth() + 1) + ' ' + pod.getHours() + ':' + pod.getMinutes()
          txt += 'De ' + opad + '</td><td> &agrave; ' + opod + '</td></tr>'
        }
        txt += '<tr' + bc + '><td colspan="3">' + mylink(pmg.tx).replace(/\n|\r/g, '<br/>') + '</td></tr>'
        objectViewed('p', n, true)
      }
    }
    txt += '</table></div></fieldset></td></tr>'
  }
  if (sit._e && sit._e.length > 0) {
    txt += '<tr><td colspan="2"><fieldset style="color:black;background-color:#FFF;"><legend align="right">'
    txt += '<span style="background-color:#F7D2EE;font-weight:bold;font-size:130%;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ' + sit._e.length + ' &Eacute;v&egrave;nement' + ((sit._e.length > 1) ? 's' : '') + ' &nbsp; &nbsp; </span>'
    txt += '<span id="oc_s_e" style="background-color:#F7D2EE;font-weight:bold;font-size:large"> ' + glo.symb.plid.c + ' </span>'
    txt += '</legend><div id="d_s_e" style="display:block;visibility:visible"><table>'
    for (const n of sit._e) {
      const evt = app.getObj('e', n)
      if (evt && app.evtMatchTime([n])) {
        const guy = app.getObj('g', evt.gi)
        const ead = new Date(evt.sa * 1000)
        const oead = dow(ead.getDay()) + ' ' + ead.getDate() + '/' + (ead.getMonth() + 1)
        const eod = new Date(evt.so * 1000)
        let na = '...'; let titna = na
        if (!guy) { app.getLater('g', evt.gi) } else {
          na = guy.sn
          titna = guy.fn + ' ' + guy.ln
          if (!na || na === '') { na = titna }
        }
        const bc = evt.seen() ? '' : ' style="background-color:#F7D2EE"'
        txt += '<tr id="tr_e_' + evt.id + '"' + bc + '><td style="font-weight:bold;" colspan="2">'
        txt += '<span id="us_e_' + evt.id + '" style="font-size:large" title="non-vu"> ' + glo.symb.nonvu.c + ' </span>'
        if (glo.me.id && (glo.me.id === evt.gi || glo.me.id === 1)) {
          txt += '<span id="ed_e_' + evt.id + '" style="font-size:large" title="modifier"> ' + glo.symb.mod.c + ' ' + evt.ti + '</span>'
        } else {
          txt += evt.ti
        }
        txt += '</td></tr>'
        txt += '<tr' + bc + '><td style="font-style:italic;"'
        if (((eod - ead) < 86400000) && (ead.getDate() === eod.getDate())) {
          txt += ' colspan="2">Le ' + oead
        } else {
          const oeod = dow(eod.getDay()) + ' ' + eod.getDate() + '/' + (eod.getMonth() + 1)
          txt += '>Du ' + oead + '</td><td style="font-style:italic;"> au ' + oeod
        }
        txt += '</td></tr>'
        txt += '<tr' + bc + '><td>Proprio</td><td><span id="go_g_' + guy.id + '" title="' + na + '"><u>' + titna + '</u></span></td></tr>'
        if (evt.tx && evt.tx.length > 0) {
          txt += '<tr' + bc + '><td colspan="2">' + mylink(evt.tx).replace(/\n|\r/g, '<br/>') + '</td></tr>'
        }
        objectViewed('e', n, true)
      }
    }
    txt += '</table></div></fieldset></td></tr>'
  }

  if (sit._v && sit._v.length > 0) {
    let precti = -1 // c8f2c6
    txt += '<tr><td colspan="2"><fieldset style="color:black;background-color:#FFF;"><legend align="right">'
    txt += '<span style="background-color:#CDFACA;font-weight:bold;font-size:130%;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ' + sit._v.length + ' Vote' + ((sit._v.length > 1) ? 's' : '') + ' &nbsp; &nbsp; </span>'
    txt += '<span id="oc_s_v" style="background-color:#CDFACA;font-weight:bold;font-size:large"> ' + glo.symb.plid.c + ' </span>'
    txt += '</legend><div id="d_s_v" style="display:block;visibility:visible"><table>'
    for (const n of sit._v) {
      const vote = app.getObj('v', n)
      if (vote && app.votMatchTime([n])) {
        const guy = app.getObj('g', vote.gi)
        const tof = app.getObj('t', vote.ti)
        let na = '...'; let titna = na
        if (!guy) { app.getLater('g', vote.gi) } else {
          na = guy.sn
          titna = guy.fn + ' ' + guy.ln
          if (!na || na === '') { na = titna }
        }
        const bc = vote.seen() ? '' : ' style="background-color:#CDFACA"'
        const vd = new Date(vote.d * 1000)
        const ovd = dow(vd.getDay()) + ' ' + vd.getDate() + '/' + (vd.getMonth() + 1)
        txt += '<tr id="tr_v_' + vote.id + '"' + bc + '><td>'
        if (glo.me.id && (glo.me.id === vote.gi || glo.me.id === 1)) {
          txt += '<span id="ed_v_' + vote.id + '" style="font-size:large" title="modifier"> ' + glo.symb.mod.c + ' </span> '
        }
        txt += '<span id="us_v_' + vote.id + '" style="font-size:large" title="non-vu"> ' + glo.symb.nonvu.c + ' </span>'
        if (tof.id !== precti) {
          txt += '<u><span id="go_t_' + tof.id + '">' + tof.n + '</span></u> '
          precti = tof.id
        } else {
          txt += '&nbsp;' + glo.symb.idem.c + glo.symb.idem.c + ' '
        }
        const ns = parseInt(vote.s)
        for (let n = ns; n > 0; n--) { txt += '&nbsp;' + glo.symb.star.c }
        txt += ' par <span id="go_g_' + guy.id + '" title="' + titna + '"><u>' + na + '</u></span></td><td> pour le ' + ovd + '</td></tr>'
        if (vote.c !== null && vote.c.length > 0) {
          txt += '<tr' + bc + '><td colspan="2">' + mylink(vote.c).replace(/\n|\r/g, '<br/>') + '</td></tr>'
        }
        objectViewed('v', n, true)
      }
    }
    txt += '</table></div></fieldset></td></tr>'
  }
  if (sit._w && sit._w.length > 0) {
    let precti = -1 // f5dfe4
    txt += '<tr><td colspan="2"><fieldset style="color:black;background-color:#FFF;"><legend align="right">'
    txt += '<span style="background-color:#BFF;font-weight:bold;font-size:130%;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ' + sit._w.length + ' Sortie' + ((sit._w.length > 1) ? 's' : '') + ' &nbsp; &nbsp; </span>'
    txt += '<span id="oc_s_w" style="background-color:#BFF;font-weight:bold;font-size:large"> ' + glo.symb.plid.c + ' </span>'
    txt += '</legend><div id="d_s_w" style="display:block;visibility:visible"><table>'
    for (const n of sit._w) {
      const wish = app.getObj('w', n)
      if (wish && app.wshMatchTime([n])) {
        const guy = app.getObj('g', wish.gi)
        const tof = app.getObj('t', wish.ti)
        let na = '...'; let titna = na
        if (!guy) { app.getLater('g', wish.gi) } else {
          na = guy.sn
          titna = guy.fn + ' ' + guy.ln
          if (!na || na === '') { na = titna }
        }
        const bc = wish.seen() ? '' : ' style="background-color:#BFF"'
        const wd = new Date(wish.d * 1000)
        const owd = dow(wd.getDay()) + ' ' + wd.getDate() + '/' + (wd.getMonth() + 1)
        txt += '<tr id="tr_w_' + wish.id + '"' + bc + '><td>'
        if (glo.me.id && (glo.me.id === wish.gi || glo.me.id === 1)) {
          txt += '<span id="ed_w_' + wish.id + '" style="font-size:large" title="modifier"> ' + glo.symb.mod.c + ' </span> '
        }
        txt += '<span id="us_w_' + wish.id + '" style="font-size:large" title="non-vu"> ' + glo.symb.nonvu.c + ' </span>'
        if (tof.id !== precti) {
          txt += '<u><span id="go_t_' + tof.id + '">' + tof.n + '</span></u> '
          precti = tof.id
        } else {
          txt += '&nbsp;' + glo.symb.idem.c + glo.symb.idem.c + ' '
        }
        txt += ' de <span id="go_g_' + guy.id + '" title="' + titna + '"><u>' + na + '</u></span></td><td>pour le <span title="#' + wish.id + '">' + owd + '</span></td></tr>'
        if (wish.c !== null && wish.c.length > 0) { txt += '<tr' + bc + '><td colspan="2">' + mylink(wish.c).replace(/\n|\r/g, '<br/>') + '</td></tr>' }
        objectViewed('w', n, true)
      }
    }
    txt += '</table></div></fieldset></td></tr>'
  }
  const max4open = 3
  if (sit._d && sit._d.length > 0) {
    txt += '<tr><td colspan="2"><fieldset style="color:black;background-color:#FFF;"><legend align="right">'
    txt += '<span style="background-color:#E6EDE8;font-weight:bold;font-size:130%;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ' + sit._d.length + ' D&eacute;tail' + ((sit._d.length > 1) ? 's' : '') + ' &nbsp; &nbsp; </span>'
    txt += '<span id="oc_s_d" style="background-color:#E6EDE8;font-weight:bold;font-size:large"> '
    txt += (sit._d.length > max4open) ? glo.symb.deplid.c : glo.symb.plid.c
    txt += ' </span></legend><div id="d_s_d" '
    txt += (sit._d.length > max4open) ? 'style="display:none;visibility:hidden">' : 'style="display:block;visibility:visible">'
    txt += '<table>'
    for (const n of sit._d) {
      const det = app.getObj('d', n)
      if (det) {
        const guy = app.getObj('g', det.gi)
        let na = '...'; let titna = na
        if (!guy) { app.getLater('g', det.gi) } else {
          na = guy.sn
          titna = guy.fn + ' ' + guy.ln
          if (!na || na === '') { na = titna }
        }
        const bc = det.seen() ? '' : ' style="background-color:#E6EDE8"'
        const dd = new Date(det.ts * 1000)
        const odd = dow(dd.getDay()) + ' ' + dd.getDate() + '/' + (dd.getMonth() + 1) + '/' + dd.getFullYear()
        txt += '<tr id="tr_d_' + det.id + '"' + bc + '><td>'
        if (glo.me.id && (glo.me.id === det.gi || glo.me.id === 1)) {
          txt += '<span id="ed_d_' + det.id + '" style="font-size:large" title="modifier"> ' + glo.symb.mod.c + ' </span> '
        }
        txt += '<span id="us_d_' + det.id + '" style="font-size:large" title="non-vu"> ' + glo.symb.nonvu.c + ' </span>'
        switch (det.m) {
          case 'w' :
            txt += '<a title="' + na + ' ' + odd + '" target="_blank" href="'
            if (typeof (det.u) === 'string') {
              if (det.u.substr(0, 4) !== 'http') { txt += 'http://' }
            }
            txt += det.u + '">' + det.tx + '</a>'
            break
          case 'e' : txt += det.tx +
                    '&nbsp;<a title="' + na + ' ' + odd + '" href="mailto:' + det.u + '?subject=From OPS" target="_blank"><img src="/img/sendmail_16.png"/></a>'
            break
          case 'a' :
            txt += '<span title="' + na + ' ' + odd + '">Altitude ' + det.tx + ' ' + det.u + ' m</span>'
            break
          case 'f' :
            txt += '<span title="' + na + ' ' + odd + '">Fr&eacute;quence ' + det.tx + ' ' + det.u + ' MHz</span>'
            break
          case 'p' :
            txt += '<span title="' + na + ' ' + odd + '">' + det.tx + ' ' + det.u + '</span>&nbsp;<a style="font-style:large" href="http://' + det.u + '" target="_blank">' + glo.symb.tel.c + '</a>'
            break
          case 'l' :
            txt += '<span title="' + na + ' ' + odd + '"><font color="red">' + det.tx + '</font></span>'
            break
          default : {
            if (det.tx.indexOf('\n') > -1) {
              txt += '<span title="' + na + ' ' + odd + '"><pre>' + mylink(det.tx).replace(/\n|\r/g, '<br/>') + '</pre></span>'
            } else {
              txt += '<span title="' + na + ' ' + odd + '">' + mylink(det.tx).replace(/\n|\r/g, '<br/>') + '</span>'
            }
          }
        }
        txt += '</td></tr>'
        objectViewed('d', n, true)
      }
    }
    txt += '</table></div></fieldset></td></tr>'
  }
  const ks = getTKindImgs(sit.k)
  if (ks && ks.length > 0) {
    txt += '<tr><td>Types de vol</td><td>'
    for (const k of ks) {
      txt += '<img title="' + k.l + '" src="' + k.i + '" width="25" /> &nbsp; '
    }
    txt += '</td></tr>'
  }
  txt += '<tr><td><span id="mc_' + ObjType + '_' + sit.id + '" style="font-size:large">' + glo.symb.navi.c + '</span> Position</td><td>' + sit.a.toFixed(5) + ' ' + sit.g.toFixed(5) + '&nbsp; Alt:' + sit.t.toFixed(0) + ' m'
  const curpos = app.getv('_pos')
  if (curpos) {
    txt += ' <a target="_blank" href="https://www.google.com/maps/preview/dir/' +
            curpos[1].toFixed(6) + ',' + curpos[0].toFixed(6) + '/' + sit.a + ',' + sit.g + '/">&lt;=Nav</a>'
  }
  txt += '</td></tr>'
  if (sit.f) { txt += '<tr><td>Voir le site sur</td><td><a href="https://intranet.ffvl.fr/sites_pratique/voir/' + sit.f + '" target="_blank">F.F.V.L. (' + sit.f + ')</a></td></tr>' }
  if (sit.p) { txt += '<tr><td>Voir le site sur</td><td><a href="https://paragliding.earth/?site=' + sit.p + '" target="_blank">ParaGlidingEarth</a></td></tr>' }
  if ((sit._t && sit._t.length > 0) ||
     (sit._l && sit._l.length > 0)) {
    txt += '<tr><td valign="top">'
    if (sit._t && sit._t.length > 0) {
      txt += '<fieldset><legend><span id="oc_s_t_left" style="font-size:large">'
      txt += (sit._t.length > max4open) ? glo.symb.deplig.c : glo.symb.plig.c
      txt += '</span>&nbsp;<span style="font-weight:bold">' + sit._t.length + ' D&eacute;co' + ((sit._t.length > 1) ? 's' : '') + '</span></legend>'
      txt += '<div id="d_s_t" '
      txt += (sit._t.length > max4open) ? 'style="display:none;visibility:hidden">' : 'style="display:block;visibility:visible">'
      for (const t of sit._t) {
        const tof = app.getObj('t', t)
        if (!tof) continue
        txt += '<u><span id="go_t_' + t + '"><span title="#' + t + '" style="'
        if (tof.main() === true) txt += 'font-weight:bold;'
        if (tof.avail() === false) txt += 'text-decoration-line:line-through;text-decoration-color:red;'
        txt += '">' + tof.n
        if (tof.landing() === true) txt += ' ' + glo.symb.land.c
        txt += '</span></span></u><br/>'
      }
      txt += '</div></fieldset>'
    }
    txt += '</td><td valign="top">'
    if (sit._l && sit._l.length > 0) {
      txt += '<fieldset><legend align="right"><span style="font-weight:bold">' + sit._l.length + ' Atterro' + ((sit._l.length > 1) ? 's' : '')
      txt += '</span>&nbsp;<span id="oc_s_l" style="font-size:large">'
      txt += (sit._l.length > max4open) ? glo.symb.deplid.c : glo.symb.plid.c
      txt += '</span></legend><div id="d_s_l" '
      txt += (sit._l.length > max4open) ? 'style="display:none;visibility:hidden">' : 'style="display:block;visibility:visible">'
      for (const l of sit._l) {
        const lan = app.getObj('l', l)
        if (!lan) continue
        txt += '<u><span id="go_l_' + l + '"><span title="#' + l + '" style="'
        if (lan.main() === true) txt += 'font-weight:bold;'
        if (lan.safe() === true) txt += 'color:#D57100;'; else txt += 'color:00A30A;'
        if (lan.avail() === false) txt += 'text-decoration-line:line-through;text-decoration-color:red;'
        txt += '">' + lan.n + '</span></span></u><br/>'
      }
      txt += '</div></fieldset>'
    }
    txt += '</td></tr>'
  }

  txt += '<tr><td colspan="2" align="center"><hr/>'
  if (glo.me.id === 1) {
    txt += '<span id="oa_s_y_' + sit.id + '" style="font-size:140%;color:#FF0000;background:#FFFFFF">&gt;Y</span> &nbsp; '
    txt += '<span id="oa_s_n_' + sit.id + '" style="font-size:140%;color:#FFFFFF;background:#FF0000">&gt;N</span> &nbsp; '
  }
  txt += '<span id="cn_p_' + sit.id + '" style="font-size:140%;background:#FFFF7A">' + glo.symb.add.c + ' Post\'It</span> &nbsp; '
  txt += '<span id="cn_e_' + sit.id + '" style="font-size:140%;background:#FA7AFA">' + glo.symb.add.c + ' &Eacute;v&egrave;nement</span> &nbsp; '
  txt += '<span id="cn_d_' + sit.id + '" style="font-size:140%;background:#CFCFCF">' + glo.symb.add.c + ' D&eacute;tail</span>'
  txt += '</td></tr>'

  txt += '</table></fieldset>'
  if (typeof sit.r !== 'undefined') {
    try {
      const eb = app.getEB()
      const sid = app.getv('sid')
      eb.send('rs', { n: sit.n.replace("'", "\\'"), i: sit.id, la: sit.a, lg: sit.g }, { sid: '' + sid, timeout: 7000 },
        function (err, r) {
          if (err) { return (null) } else { fillDays(r.body) }
        }
      )
    } catch (err) { console.error('SendRS err: ' + err) }
    //        eb.send('rs', {'n':sit.name.replace("'", "\\'"),'i':sit.id, 'la':sit.a, 'lg':sit.g}, {'timeout':5000}, function(non,ar) { fillDays(ar.body); });
  }
  //    if( typeof sit.mc !== 'undefined' && sit.mc !== null ) {
  //        eb.send('ms', {'n':sit.name.replace("'", "\\'"),'i':sit.id, 'la':sit.lat, 'lg':sit.lgt}, {'timeout':5000}, function(non,ar) { mcDays(ar.body); });
  //    }
  return (txt)
}

function sitLst (list) {
  if (list) list.forEach((ojs) => sitUpd(ojs))
}

function getSit (oid) {
  const co = app.getObj(ObjType, oid)
  if (co) { sitUpd(co); return }
  try {
    const eb = app.getEB()
    eb.send('go', { id: oid, ot: ObjType }, { sid: '' + app.getv('sid') },
      function (err, r) {
        if (err) { return (null) } else { sitIns(r.body) }
      }
    )
  } catch (err) { console.error('GetSit err: ' + err) }
}

function sitIns (ojs) {
  const obj = new Site(ojs)
  const id = obj.id
  //    if( app.getObjs(ObjType)[id] ) { app.getObjs(ObjType)[id].remove(); }
  app.getObjs(ObjType)[id] = obj
  obj.check()
}

function sitUpd (ojs) {
  let obj = app.getObj(ObjType, ojs.id)
  if (obj) {
    obj.update(ojs)
  } else {
    obj = new Site(ojs)
    app.getObjs(ObjType)[obj.id] = obj
  }
  obj.check()
}

function sitDel (obj) {
  const ro = app.getObj(ObjType, obj.id)
  if (ro) ro.remove()
}

function _sitFlDecorate (img, cote, fl, pct) {
  const cnv = document.createElement('canvas')
  const ctx = cnv.getContext('2d', { willReadFrequently: true })
  ctx.canvas.width = cote
  ctx.canvas.height = cote
  ctx.drawImage(img, 0, 0)
  const w = cote / 3.4
  const cen = cote / 2.0
  let colo
  switch (fl) {
    case 1: colo = 'rgba(0,0,255,' + pct + ')'; break
    case 2: colo = 'rgba(0,255,0,' + pct + ')'; break
    case 3: colo = 'rgba(255,170,0,' + pct + ')'; break
    case 4: colo = 'rgba(255,0,0,' + pct + ')'; break
    default:colo = 'rgba(0,0,0,0.)'; break
  }
  ctx.beginPath()
  ctx.arc(cen, cen, w, 0, 2 * Math.PI, false)
  ctx.fillStyle = colo
  ctx.fill()
  const newimg = new Image()
  newimg.width = cote
  newimg.height = cote
  newimg.src = cnv.toDataURL()
  return newimg
}

const sitStyle = function (f, reso) {
  if (!f || typeof f === 'undefined') return []
  const sit = app.getObj(ObjType, f.getId())
  if (!sit || typeof sit === 'undefined') return []
  const key = sit.ik
  let img = sitImgCache[key]
  const baseSq = app.getv('bSq')
  const factor = app.getv('df')
  const cote = Math.round(baseSq * factor)
  if (!img || img === null || typeof img === 'undefined') {
    prepareSitImg(key)
    img = sitImgCache[key]
  }

  if (app.getv('dec') === true) {
    img = oridecorate(img, cote, sit.oo, sit.ao, 0)
  }

  if (sit.fl && sit.fl > 0) {
    const itmillis = app.getv('it') * 3600000
    const inf = app.getObj('i', sit._i[0])
    if (inf) {
      const now = new Date()
      const tch = new Date(inf.ts * 1000)
      const agemillis = now.getTime() - tch.getTime()
      img = _sitFlDecorate(img, cote, sit.fl, 1.03 - (agemillis / itmillis))
    }
  }

  const sty = new Style({
    image: new Icon({
      anchor: [0.5, 0.5],
      anchorXUnits: 'fraction',
      anchorYUnits: 'fraction', // pixels
      imgSize: [cote, cote],
      img
    })
  })
  return [sty]
}

function prepareSitImg (k) {
  if (!sitImgCache[k] || typeof sitImgCache[k] === 'undefined' || sitImgCache[k] === null) {
    sitImgCache[k] = getSitImg(k)
  }
}
// function clearSitImgCache () { sitImgCache = {} }

function _getSitKey (site) {
  if (!site) { return ObjType + '0' }
  let mask = 0
  if (site._d && site._d.length > 0) {
    mask |= mskDeta
    if (app.detUnseenNumber(site._d) > 0) mask |= mskDetU
  }
  if (site._e && site._e.length > 0 && app.evtMatchTime(site._e) > 0) { // mask |= 2;
    //        mask |= (0x07 & app.evtUnseenNumber(site._e)) << 7;
    mask |= mskEven
    if (app.evtUnseenNumber(site._e) > 0) mask |= mskEveU
  }
  if (site._p && site._p.length > 0 && app.pmgMatchTime(site._p) > 0) { // mask |= 4;
    //        mask |= (0x07 & app.pmgUnseenNumber(site._p)) << 10;
    mask |= mskPmsg
    if (app.pmgUnseenNumber(site._p) > 0) mask |= mskPmsU
  }
  if (site._v && site._v.length > 0 && app.votMatchTime(site._v) > 0) { // mask |= 8;
    //        mask |= (0x07 & app.votUnseenNumber(site._v)) << 13;
    mask |= mskVots
    if (app.votUnseenNumber(site._v) > 0) mask |= mskVotU
  }
  if (site._w && site._w.length > 0 && app.wshMatchTime(site._w) > 0) { // mask |= 16;
    //        mask |= (0x07 & app.wshUnseenNumber(site._w)) << 16;
    mask |= mskWshs
    if (app.wshUnseenNumber(site._w) > 0) mask |= mskWshU
  }
  if (site.av) { mask |= mskAvai }
  if (site.p || site.f || site.nf) { mask |= mskOffi } // official
  return ObjType + mask
}

function setSitPts (bas, fac) {
  const c = Math.round(bas * fac)
  sitPts = [
    [c * 0.2, 0.0],
    [c * 0.25, c * 0.12],
    [c * 0.4, c * 0.12],
    [c * 0.3, c * 0.21],
    [c * 0.35, c * 0.4],
    [c * 0.2, c * 0.3],
    [c * 0.05, c * 0.4],
    [c * 0.1, c * 0.21],
    [0.0, c * 0.12],
    [c * 0.15, c * 0.12]
  ]
  localFactor = fac
}

function getSitImg (key) {
  const k = parseInt(key.substr(1))
  const baseSq = app.getv('bSq')
  const factor = app.getv('df')
  const cote = Math.round(baseSq * factor)
  const cen = cote / 2
  const epa = cote / 30
  if (sitPts.length < 1 || localFactor !== factor) { setSitPts(baseSq, factor) }
  const bDets = (k & mskDeta) === mskDeta // details
  const bDetU = (k & mskDetU) === mskDetU // details unseen
  const bEvts = (k & mskEven) === mskEven // events
  const bEvtU = (k & mskEveU) === mskEveU // events unseen
  const bPmgs = (k & mskPmsg) === mskPmsg // post-its
  const bPmgU = (k & mskPmsU) === mskPmsU // post-its unseen
  const bVots = (k & mskVots) === mskVots // votes
  const bVotU = (k & mskVotU) === mskVotU // votes unseen
  const bWshs = (k & mskWshs) === mskWshs // wishes
  const bWshU = (k & mskWshU) === mskWshU // wishes unseen
  const bavai = (k & mskAvai) === mskAvai // avail (or red-cross)
  const boffi = (k & mskOffi) === mskOffi // official (or dashed)

  const cnv = document.createElement('canvas')
  cnv.style.width = cote
  cnv.style.height = cote
  cnv.setAttribute('width', cote)
  cnv.setAttribute('height', cote)
  const ctx = cnv.getContext('2d', { willReadFrequently: true })
  ctx.canvas.width = cote
  ctx.canvas.height = cote

  ctx.lineWidth = epa
  if (!boffi) { ctx.setLineDash([6, 4]) }
  ctx.beginPath()
  ctx.arc(cen, cen, cen - epa, 0, 2 * Math.PI, false)
  ctx.closePath()
  ctx.strokeStyle = '#000'
  ctx.stroke()

  ctx.beginPath()
  ctx.arc(cen, cen, cen - epa - epa, 0, 2 * Math.PI, false)
  ctx.closePath()
  ctx.strokeStyle = '#FFF'
  ctx.stroke()
  ctx.setLineDash([])

  if (bDets) {
    const larg = cote / 2
    const xdebut = cote / 4
    const haut = cote / 8
    ctx.beginPath()
    ctx.moveTo(xdebut, cote)
    ctx.lineTo(xdebut, cote - haut)
    ctx.lineTo(xdebut + larg, cote - haut)
    ctx.lineTo(xdebut + larg, cote)
    ctx.closePath()
    ctx.fillStyle = bDetU ? '#CCC' : '#FFF'
    ctx.fill()

    //        ctx.strokeStyle = bDetU ?'#000' :'#CCC';
    ctx.strokeStyle = '#000'
    //        ctx.lineWidth = bDetU ?1 :2;
    ctx.lineWidth = 1
    ctx.stroke()
  }

  if (bEvts) {
    const w = cote / 6.0
    ctx.beginPath()
    ctx.arc(w, cote - w, w - 1, 0, 2 * Math.PI, false)
    ctx.fillStyle = bEvtU ? '#FA58F4' : '#FFF'
    ctx.fill()

    //        ctx.strokeStyle = bEvtU ?'#000' :'#FA58F4';
    ctx.strokeStyle = '#000'
    ctx.lineWidth = bEvtU ? 1 : 2
    ctx.stroke()
  }

  if (bPmgs) {
    const w = cote / 3
    ctx.beginPath()
    ctx.moveTo(cote, cote)
    ctx.lineTo(cote - w, cote)
    ctx.lineTo(cote - w, cote - w)
    ctx.lineTo(cote, cote - w)
    ctx.lineTo(cote, cote)
    ctx.closePath()
    ctx.fillStyle = bPmgU ? '#FF0' : '#FFF'
    ctx.fill()

    //        ctx.strokeStyle = bPmgU ?'#000' :'#FF0';
    ctx.strokeStyle = '#000'
    ctx.lineWidth = bPmgU ? 1 : 2
    ctx.stroke()
  }

  if (bVots) {
    ctx.beginPath()
    ctx.moveTo(sitPts[0][0], sitPts[0][1])
    for (let idx = 1, len = sitPts.length; idx < len; idx++) {
      ctx.lineTo(sitPts[idx][0], sitPts[idx][1])
    }
    ctx.closePath()
    ctx.fillStyle = bVotU ? '#0F0' : '#FFF'
    ctx.fill()

    //        ctx.strokeStyle = bVotU ?'#000' :'#0F0';
    ctx.strokeStyle = '#000'
    ctx.lineWidth = bVotU ? 1 : 2
    ctx.stroke()
  }

  if (bWshs) {
    const w = cote / 3.0
    const x = cote / 6.0
    ctx.beginPath()
    ctx.moveTo(cote - w, w)
    ctx.lineTo(cote - x, 0)
    ctx.lineTo(cote, 0)
    ctx.lineTo(cote, x)
    ctx.lineTo(cote - w, w)
    ctx.closePath()
    ctx.fillStyle = bWshU ? '#0FF' : '#FFF'
    ctx.fill()

    //        ctx.strokeStyle = bWshU ?'#000' :'#0FF';
    ctx.strokeStyle = '#000'
    ctx.lineWidth = bWshU ? 1 : 2
    ctx.stroke()
  }

  if (!bavai) {
    const t = 7
    const c = cote - t
    ctx.beginPath()
    ctx.moveTo(c, t)
    ctx.lineTo(t, c)
    ctx.moveTo(c, c)
    ctx.lineTo(t, t)
    ctx.closePath()
    ctx.strokeStyle = '#F00'
    ctx.lineWidth = 3
    ctx.setLineDash([5, 5])
    ctx.stroke()
  }

  const img = new Image()
  img.width = cote
  img.height = cote
  img.src = cnv.toDataURL()
  return img
}

class Site extends BaseObject {
  constructor (ojs) {
    super()
    this.ot = ObjType
    for (const key in ojs) { this[key] = ojs[key] }
    this.ot = ObjType
    this.ik = _getSitKey(ojs)
    this.draw()
  }

  update (ojs) {
    if (!ojs) {
      const nik = _getSitKey(this)
      if (nik !== this.ik) {
        this.ik = nik
        this.refresh()
      }
    } else {
      let changed = false
      for (const key in ojs) {
        if (!this.key || this[key] !== ojs[key]) {
          changed = true
          this[key] = ojs[key]
        }
      }
      if (!this._t) this._t = []
      if (!this._l) this._l = []
      const nik = _getSitKey(this)
      if (nik !== this.ik) {
        this.ik = nik
        changed = true
      }
      if (changed) this.refresh()
    }
  }

  draw () {
    prepareSitImg(this.ik)
    const fea = new Feature({
      geometry: new Point(app.tr2map([this.g, this.a, this.t]), 'XYZ'),
      ot: ObjType
    })
    fea.setId(this.id)
    const sSs = app.getSrcs(ObjType)
    const sSsw = app.getSrcs(ObjType + 'w')
    if ((this.fl && this.fl > 0) ||
         (this._e && this._e.length > 0) ||
         (this._p && this._p.length > 0) ||
         (this._w && this._w.length > 0) ||
         (this._v && this._v.length > 0)) {
      sSs.addFeature(fea)
    } else {
      sSsw.addFeature(fea)
    }
    fea.changed()
  }

  undraw () {
    const sSs = app.getSrcs(ObjType)
    const sSsw = app.getSrcs(ObjType + 'w')
    let fea = sSs.getFeatureById(this.id)
    if (fea) {
      sSs.removeFeature(fea)
    } else {
      fea = sSsw.getFeatureById(this.id)
      if (fea) sSsw.removeFeature(fea)
    }
  }

  refresh () {
    const fea = app.getSrcs(ObjType).getFeatureById(this.id)
    if (fea) fea.changed()
  }

  linked (ot, oid) {
    switch (ot) {
      case 'd': return (this._d && this._d.indexOf(oid) > -1)
      case 'e': return (this._e && this._e.indexOf(oid) > -1)
      case 'i': return (this._i && this._i.indexOf(oid) > -1)
      case 'l': return (this._l && this._l.indexOf(oid) > -1)
      case 'p': return (this._p && this._p.indexOf(oid) > -1)
      case 't': return (this._t && this._t.indexOf(oid) > -1)
      case 'v': return (this._v && this._v.indexOf(oid) > -1)
      case 'w': return (this._w && this._w.indexOf(oid) > -1)
    }
    return false
  }

  link (ot, oid) {
    switch (ot) {
      case 'd': if (this._d && this._d.indexOf(oid) < 0) { this._d.push(oid) }
        this.update()
        break
      case 'e': if (this._e && this._e.indexOf(oid) < 0) { this._e.push(oid) }
        this.update()
        break
      case 'p': if (this._p && this._p.indexOf(oid) < 0) { this._p.push(oid) }
        this.update()
        break
      case 'i': {
        if (this._i && this._i.indexOf(oid) < 0) { this._i.push(oid) }
        const info = app.getObj('i', oid)
        if (info) { this.fl = info.fl }
        this.update()
      } break
      case 'v': if (this._v && this._v.indexOf(oid) < 0) { this._v.push(oid) }
        this.update()
        break
      case 'w': if (this._w && this._w.indexOf(oid) < 0) { this._w.push(oid) }
        this.update()
        break
      case 't': if (this._t && this._t.indexOf(oid) < 0) { this._t.push(oid) }
        this.update()
        break
      case 'l': if (this._l && this._l.indexOf(oid) < 0) { this._l.push(oid) }
        this.update()
        break
    }
  }

  unlink (ot, oid) {
    let ix
    switch (ot) {
      case 'd': if (this._d && (ix = this._d.indexOf(oid)) > -1) { this._d.splice(ix, 1) }
        this.update()
        break
      case 'e': if (this._e && (ix = this._e.indexOf(oid)) > -1) { this._e.splice(ix, 1) }
        this.update()
        break
      case 'p': if (this._p && (ix = this._p.indexOf(oid)) > -1) { this._p.splice(ix, 1) }
        this.update()
        break
      case 'i': if (this._i && (ix = this._i.indexOf(oid)) > -1) { this._i.splice(ix, 1) }
        this.update()
        break
      case 'v': if (this._v && (ix = this._v.indexOf(oid)) > -1) { this._v.splice(ix, 1) }
        this.update()
        break
      case 'w': if (this._w && (ix = this._w.indexOf(oid)) > -1) { this._w.splice(ix, 1) }
        this.update()
        break
      case 't': if (this._t && (ix = this._t.indexOf(oid)) > -1) { this._t.splice(ix, 1) }
        this.update()
        break
      case 'l': if (this._l && (ix = this._l.indexOf(oid)) > -1) { this._l.splice(ix, 1) }
        this.update()
        break
    }
  }

  remove () {
    const id = this.id
    this.undraw()
    app.getObjs(ObjType)[id] = null
    delete app.getObjs(ObjType)[id]
  }

  check () {
    if (this._d) for (const id of this._d) { if (!app.getObj('d', id)) app.getLater('d', id) }
    if (this._e) for (const id of this._e) { if (!app.getObj('e', id)) app.getLater('e', id) }
    if (this._p) for (const id of this._p) { if (!app.getObj('p', id)) app.getLater('p', id) }
    if (this._i) for (const id of this._i) { if (!app.getObj('i', id)) app.getLater('i', id) }
    if (this._v) for (const id of this._v) { if (!app.getObj('v', id)) app.getLater('v', id) }
    if (this._w) for (const id of this._w) { if (!app.getObj('w', id)) app.getLater('w', id) }
  }

  getDisplay () { return (_sitDisplay(this.id)) }
  getOneLiner () { return (sitOneLiner(this.id)) }
}

export { Site, sitStyle, getSit, sitLst, sitIns, sitUpd, sitDel }
