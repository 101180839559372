import BaseObject from 'ol/Object'

import { app, glo } from './globo'
import { isMyfriend } from './libra'

const ObjType = 'g'

// guy.id,
// date_part('epoch'::text, guy.ts)::integer AS ts,
// guy.login AS lo,
// guy.pass AS pa,
// guy.fname AS fn,
// guy.lname AS ln,
// guy.sname AS sn,
// guy.oper AS o,
// guy.phone AS p,
// guy.email AS e,
// guy.did AS d,
// guy.sim AS s,
// guy.sdk AS k,
// guy.apk AS v,
// guy.aware AS w,
// guy.comm AS c,
// guy.slk AS k,
// guy.sli AS i,
// guy.ymt AS y,             // unused (youmethem is gone)
// pgc_isfriend(.) AS _f

// local
function _guyEdit (guy) {
  let oe = guy.e
  if (!oe) { oe = '' }
  let otk = guy.k
  if (!otk) { otk = '' }
  let txt = '<form id="frm_' + ObjType + '"><fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Profil</span></legend><table>'
  txt += '<input type="hidden" id="ih_id" value="' + guy.id + '"/>'
  txt += '<tr><td>Login</td><td><input disabled type="text" value="' + guy.lo + '" maxlength="32" size="16"/></td></tr>'
  txt += '<tr><td>Pr&eacute;nom</td><td><input id="it_fn" type="text" value="' + guy.fn + '" maxlength="32" size="16"/></td></tr>'
  txt += '<tr><td>Nom</td><td><input id="it_ln" type="text" value="' + guy.ln + '" maxlength="32" size="16"/></td></tr>'
  txt += '<tr><td>Surnom</td><td><input id="it_sn" type="text" value="' + ((guy.sn) ? guy.sn : '') + '" maxlength="32" size="16" placeholder="le meilleur..."/></td></tr>'
  txt += '<tr><td>T&eacute;l&eacute;phone</td><td><input id="it_p" type="text" value="' + guy.p + '" maxlength="16" size="16" placeholder="+33 0........."/></td></tr>'
  txt += '<tr><td>@email</td><td><input id="it_e" type="text" value="' + oe + '" maxlength="64" size="16" placeholder="fly@bouze.com"/></td></tr>'
  txt += '<tr><td>Cl&eacute;</td><td><input id="it_k" type="text" value="' + otk + '" maxlength="8" size="8" placeholder="CAFEBABE"/></td></tr>'
  if (glo.me.id && glo.me.id === 1) {
    if (guy.w) {
      txt += '<tr><td>Aware</td><td><input disabled type="checkbox" value="w"'
      if (guy.w) { txt += ' checked' }
      txt += '></td></tr>'
      txt += '<tr><td>---</td><td><input disabled type="text" value="' + guy.pa + '" maxlength="64" size="16" placeholder="...secret..."/></td></tr>'
    }
    txt += '<tr><td><span id="b_rp_' + guy.id + '">' + glo.symb.trash.c + '&nbsp;Reset PW</span>'
  } else { txt += '<tr><td>' }
  txt += '</td><td align="right"><span id="b_ok" title="OK">Envoi&nbsp;' + glo.symb.valid.c + '</span></td></tr>'
  txt += '</table></fieldset></form>'
  return txt
}

function _guyDisplay (oid) {
  let guy
  if (typeof oid === 'number') {
    guy = app.getObj(ObjType, oid)
    if (!guy) { getGuy(oid); return '' }
  } else { guy = app.getObj(ObjType, oid.id) }
  app.selectObj(ObjType, guy.id)
  let txt = '<fieldset><legend><span id="b_back"></span> &nbsp; <span id="b_cl" title="' + guy.id + '"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Pilote</span></legend><table>' +
    '<tr><td>Pr&eacute;nom</td><td>' + guy.fn + '</td></tr>' +
    '<tr><td>Nom</td><td>' + guy.ln + '</td></tr>' +
    '<tr><td>Surnom</td><td>' + ((guy.sn !== null) ? guy.sn : '') + '</td></tr>' +
    '<tr><td>T&eacute;l&eacute;phone</td><td><a style="font-style:larger" href="tel://' + guy.p + '" target="_blank">' + glo.symb.tel.c + '</a> &nbsp; <a style="font-style:larger" href="sms:' + guy.p + '" target="_blank">' + glo.symb.sms.c + '</a> ' + guy.p + '</td></tr>' +
    '<tr><td>@email</td><td>'
  if (guy.e) {
    txt += '<a style="font-style:larger" href="mailto:' + guy.e + '?subject=From OPS" target="_blank"><img src="/img/sendmail_16.png"/></a> ' + guy.e
  } else { txt += '-' }
  txt += '</td></tr>'
  if (guy.k) { txt += '<tr><td>Cl&eacute;</td><td>' + guy.k + '</td></tr>' }
  if (glo.me.id && (glo.me.id === guy.id)) {
    txt += '<tr><td colspan="2"><div id="exe_friends" style="display:block;visibility:hidden"></div></td></tr>'
    txt += '<tr><td colspan="2" align="center"><span id="ed_' + ObjType + '_' + guy.id + '">' + glo.symb.mod.c + ' Modifier</span></td></tr>'
  } else {
    txt += '<tr><td colspan="2" align="center">'
    //        <span id="sf_'+guy.id+'">&#x2E28;Ami.e&#x2E29;</span>
    if (isMyfriend(guy.id)) {
      //            txt += '<span id="uf_'+guy.id+'" title="Pas Ami">&#x2E28;<strike>Ami.e</strike>&#x2E29;</span>';
      txt += '<span id="uf_' + guy.id + '" title="Pas Ami">-> ' + glo.symb.unfrd.c + '</span>'
    } else {
      //            txt += '<span id="sf_'+guy.id+'" title="Nouvel Ami">&#x2E28;Ami.e&#x2E29;</span>';
      txt += '<span id="sf_' + guy.id + '" title="Nouvel Ami">-> ' + glo.symb.frd.c + '</span>'
    }
    txt += '</td></tr>'
  }
  txt += '</table></fieldset>'
  return (txt)
}

function guyLst (list) {
  if (list) list.forEach((ojs) => guyIns(ojs))
}

function getGuy (oi) {
  const co = app.getObj(ObjType, oi)
  if (co) { guyUpd(co); return }
  app.getLater(ObjType, oi)
}

function guyIns (ojs) {
  const no = new Guy(ojs)
  const id = no.id
  if (typeof app.getObjs(ObjType)[id] !== 'undefined') { app.getObjs(ObjType)[id].remove() }
  app.getObjs(ObjType)[id] = no
}

function guyUpd (ojs) {
  let obj = app.getObj(ObjType, ojs.id)
  if (obj) {
    obj.update(ojs)
  } else {
    obj = new Guy(ojs)
    app.getObjs(ObjType)[obj.id] = obj
  }
}

function guyDel (obj) {
  const ro = app.getObj(ObjType, obj.id)
  if (ro) ro.remove()
}

class Guy extends BaseObject {
  constructor (obj) {
    super()
    this.ot = ObjType
    for (const key in obj) { this[key] = obj[key] }
    if (this._f === null || typeof this._f === 'undefined') this._f = false
  }

  setFriend (yon) {
    if (typeof this._f === 'undefined' || this._f !== yon) {
      this._f = yon
      // TODO: call (un)setFriend server
    }
  }

  friend () { return (!!((typeof this._f !== 'undefined' && this._f === true))) }

  update (ojs) {
    let changed = false
    for (const key in ojs) {
      if (this[key] !== ojs[key]) {
        changed = true
        this[key] = ojs[key]
      }
    }
    if (this._f === null || typeof this._f === 'undefined') this._f = false
    if (changed) {
      const conns = app.getObjs('0')
      if (typeof conns !== 'undefined') {
        conns.forEach(function (cnx) {
          if (cnx.gid === this.id) cnx.refresh()
        })
      }
      this.refresh()
    }
  }

  remove () {
    delete app.getObjs(ObjType)[this.id]
    const conns = app.getObjs('0')
    if (typeof conns !== 'undefined' && conns != null) {
      conns.forEach(function (cnx) {
        if (cnx.gid === this.id) cnx.refresh()
      })
    }
  }

  refresh () {
    const conns = app.getObjs('0')
    if (typeof conns !== 'undefined') {
      conns.forEach(function (cnx) {
        if (cnx.gid === this.id) cnx.refresh()
      })
    }
  }

  getEdit () { return (_guyEdit(this)) }
  getDisplay () { return (_guyDisplay(this)) }
}

export { Guy, getGuy, guyLst, guyIns, guyUpd, guyDel }
